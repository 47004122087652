import React from 'react';
import { Accordion } from 'react-bootstrap';

interface RecursiveAccordionProps {
    data: any[]; // Los datos de cada nivel
    parentKey: string; // Clave única para evitar conflictos en el Accordion
}

const RecursiveAccordion: React.FC<RecursiveAccordionProps> = ({ data, parentKey }) => {
    return (
        <Accordion>
            {data.map((item, index) => {
                const currentKey = `${parentKey}-${index}`; // Generar una clave única para cada nivel
                const { titulo, cuerpo, children, id_enlace, tipo, url, img_principal } = item;

                return (
                    <Accordion.Item eventKey={currentKey} key={currentKey}>
                        <Accordion.Header>{titulo}</Accordion.Header>
                        <Accordion.Body>
                            {/* Si hay hijos, renderizar el componente recursivo */}
                            {(Array.isArray(children) && children.length > 0 || tipo === "titulo") ? (
                                <RecursiveAccordion data={children} parentKey={currentKey} />
                            ) : (
                                <>
                                    {
                                        // Manejar el tipo de contenido con una función o directamente
                                        tipo === "file" ? (
                                            <a href={`/media/${id_enlace}/files/${url}`}>{titulo}</a>
                                        ) : tipo === "url" ? (
                                            <a href={url} target="_blank" rel="noopener noreferrer">{titulo}</a>
                                        ) : tipo === "info" ? (
                                            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: cuerpo }} />
                                        ) : tipo === "imagen" ? (
                                            <img src={`https://api.upchiapas.edu.mx/images/${id_enlace}/${img_principal}`} width="100%" />
                                        ) : null
                                    }
                                </>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                );
            })}
        </Accordion>
    );
};

export default RecursiveAccordion;