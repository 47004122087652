import React from 'react';
import axios from 'axios';
import 'bootstrap-icons/font/bootstrap-icons.css';
import RecursiveAccordionItem from './recursiveAcord';
import { Accordion } from 'react-bootstrap';

const API_URL = process.env.REACT_APP_API_URL;

interface AccordProps {
    id_enlace: string;
    titulo: string;
    url: string;
}

const AccordContent: React.FC<AccordProps> = ({ id_enlace, titulo, url }) => {
    const [childs, setChilds] = React.useState<any[]>([]);

    const getChilds = async () => {
        try {
            const response = await axios.post(`${API_URL}/enlaces/acord`,
                { id: id_enlace }
            )
            const items = response.data
            setChilds(items)
        } catch (error) {
            console.log('Error: ', error)
        }
    }
    React.useEffect(() => {
        getChilds();
    }, [id_enlace])

    return (
        <>
            <h2 id={url}>{titulo}</h2>
            <Accordion defaultActiveKey="0">
                <RecursiveAccordionItem data={childs} parentKey="root" />
            </Accordion>
        </>
    )
}

export default AccordContent;