import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const FileDisplay = () => {
    const { id_enlace, filename } = useParams();
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        // Construct the URL for the file from the backend
        const filePath = `${API_URL}/files/${id_enlace}/${filename}`;
        setFileUrl(filePath);
    }, [id_enlace, filename]);

    // Helper function to detect Microsoft Office files
    const isOfficeFile = (filename: any) => {
        const officeExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
        return officeExtensions.some((ext) => filename?.toLowerCase().endsWith(ext));
    };

    // Render based on file type
    const renderFile = () => {
        if (filename?.endsWith('.pdf')) {
            return (
                <embed
                    src={fileUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            );
        } else if (filename?.endsWith('.jpg') || filename?.endsWith('.png')) {
            return <img src={fileUrl} alt={filename} style={{ maxWidth: '100%' }} />;
        } else if (isOfficeFile(filename)) {
            // Use Google Docs Viewer or Microsoft Office Viewer
            const viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileUrl)}`;
            return (
                <iframe
                    src={viewerUrl}
                    width="100%"
                    height="600px"
                    title={filename}
                    style={{ border: 'none' }}
                />
            );
        } else {
            return (
                <a href={fileUrl} download={filename}>
                    Click aquí para descargar el archivo
                </a>
            );
        }
    };

    return (
        <div>
            <h2>Descargando Archivo: {filename}</h2>
            {fileUrl && renderFile()}
        </div>
    );
};

export default FileDisplay;
